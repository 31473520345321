// exports.CLIENT_URL = "http://localhost:8082";
// exports.BASE_URL = "http://localhost:5001";
// exports.SOCKET_URL = "http://localhost:5001";

exports.CLIENT_URL = "https://bo.grillbills.com";
exports.BASE_URL = "https://bo.grillbills.com/server";
exports.SOCKET_URL = "https://bo.grillbills.com/server";

exports.SUBSCRIPTION_KEY = "thisismysubscriptionkey"

exports.API_ROUTES = {
  AUTH: {
    LOGIN: "/api/auth/login",
    LOGOUT: "/api/auth/logout",
    SESSION: "/api/auth/session",
  },
  CLIENTS: {
    GET: "/api/customers/",
    CREATE: "/api/customers/",
    CLIENT_BY_ID: "/api/customers/",
    count: "/api/customers/count",
    status: "/api/customers/status",
    GetCustomerBySalesMan: '/api/customers/getAllCustomerBYSalesManId/'
  },
  LICENSES: {
    GET: "/api/licenses/",
    GENERATE: "/api/licenses/",
    BLOCK: "/api/licenses/blockLicense",
    DeleteLicense: "/api/licenses/"
  },
  USERS: {
    GET: "/api/user",
    CREATE: "/api/user",
    GETALLROLES: "/api/user/getAllRoles",
    CHANGESTATUS: "/api/user/changeStatus",
    RESETPASSWORD: "/api/user/resetPassword"
  },
  MASTER_DATA: {
    GET: "/api/masterData/master_data",
  },
  MASTER_LICENSE: {
    GET: "/api/masterLicense",
    CREATE: "/api/masterLicense",
    UPDATE: "/api/masterLicense",
  },
  SALES_OFFICE: {
    GET: "/api/salesOffice/getAll",
    CREATE: "/api/salesOffice",
    UPDATE: "/api/salesOffice",
    CHANGESTATUS: "/api/salesOffice/changeStatus",
  },
  CHAINS: {
    GET: "/api/chains/getAll",
    CREATE: "/api/chains",
    UPDATE: "/api/chains",
    CHANGESTATUS: "/api/chains/changeStatus",
  },
  SECTIONS: {
    GET: "/api/sections",
    CREATE: "/api/sections",
    UPDATE: (id) => { return `/api/sections/${id}` },
    DELETE: (id) => { return `/api/sections/${id}` }
  },
  TABLES: {
    GET: "/api/tables",
    CREATE: "/api/tables",
    DELETE: "/api/tables",
    CREATE_BULK: "/api/tables/bulk",
  },
  STATIONS: {
    GET: "/api/stations",
    CREATE: "/api/stations",
    UPDATE: (id) => { return `/api/stations/${id}` },
    DELETE: (id) => { return `/api/stations/${id}` }
  },
  CATEGORY: {
    GET: "/api/categories",
    CREATE: "/api/categories",
    UPDATE: "/api/categories/edit-categories",
    DELETE: "/api/categories/delete-categories"
  },
  SUB_CATEGORY: {
    GET: "/api/sub-categories",
    CREATE: "/api/sub-categories",
    EDIT: "/api/sub-categories/edit-sub-categories",
    DELETE: "/api/sub-categories/delete-sub-categories"
  },
  MENU_ITEMS: {
    GET: "/api/menu-items",
    CREATE: "/api/menu-items",
    UPDATE: "/api/menu-items/edit-menu-item",
    DELETE: "/api/menu-items",
    CREATE_SUB_MENU_ITEM: "/api/menu-items/create-new-sub-menu-item",
    EDIT_SUB_MENU_ITEM: "/api/menu-items/edit-sub-menu-item",
    DELETE_SUB_MENU_ITEM: "/api/menu-items/delete-sub-menu",
    GET_MENU_ITEM_BY_ID: "/api/menu-items/getMenuById",
    GET_MENU_BY_CATEGORY_ID: "/api/menu-items/getMenuByCategoryId"
  },
  CUSTOMIZATION: {
    GET: "/api/customization",
    CREATE: "/api/customization",
    UPDATE: "/api/customization/edit-customization",
    DELETE: "/api/customization/delete-customization",
  },
  report: {
    genrate: "/api/reports/generate",
    getReportLink: "/api/reports/download",
    deleteReport: (id) => { return `/api/reports/${id}` }
  },
  LICENSE_ADDON: {
    create: "/api/license-addon",
    update: (id) => { return `/api/license-addon/${id}` },
    get: "/api/license-addon",
    getById: (id) => { return `/api/license-addon/${id}` },
    deleteById: (id) => { return `/api/license-addon/${id}` }
  }
};
